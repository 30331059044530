<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <MITutorListPopup v-if="pop.isShowTutorList" @close="pop.hideTutorAdd"></MITutorListPopup>
        <MIViewPopup :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowSchedule" @close="pop.hideSchedule"></MIViewPopup>

        <CarrotTitle title="전임교수(MI) 스케줄보기">
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="bbs.addVacation" class="btn-default">휴가 등록</button>
                <button @click="pop.showTutorAdd" class="btn-default ml-10">전임교수(MI) 추가</button>
            </div>
        </CarrotTitle>

        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="day-select">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
                        <carrot-date-picker @change="bbs.doSearch" v-model="bbs.today" class="inline-block w-160px"></carrot-date-picker>
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>

                    <div class="dp-table w-100per mt-40 mb-20">
                        <button class="btn-default float-left w-70px active">일</button>
                        <button @click="bbs.showMonth" class="btn-default float-left w-70px ml-5">월</button>
                        <button v-if="store.state.gthr_auth" @click="bbs.addSchedule('0', '')" class="btn-default float-right">스케줄 예약</button>
                    </div>

                    <div class="dayTable-box">
                        <div class="day-time">
                            <p v-for="(time, i) in bbs.time_labels" :key="i">{{ time }}</p>
                        </div>
                        <div class="div-dayTable">
                            <div class="colgroup">
                                <div v-for="i in bbs.list" :key="i" :style="{ 'width':1154/bbs.list.length+'px'}" class="col"></div>
                            </div>

                            <div class="thead">
                                <div class="tr">
                                    <div v-for="(irow, i) in bbs.list" :key="i" class="cell th">
                                        <p class="tit">{{ irow.tutor_ename }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="tbody">
                                <!-- 30분간격 행 loop -->
                                <template v-for="(time, i) in bbs.time_labels">
                                    <div :key="i" v-if="i<bbs.time_count" class="tr">
                                        <!-- 차량별 loop -->
                                        <div v-for="(irow, i) in bbs.list" :key="i" class="cell td">
                                            <!-- 예약이 없는경우 <button class="click"></button> -->
                                            <button @click="bbs.addSchedule(irow.idx, time)" class="click"></button>
                                            <!-- 예약이 있는경우 
                                                예약한 분이 30분 이하일때 top은 7px 고정값, height 55px 고정값
                                                예약한 분이 30분 이상일때 top은 35px 고정값, height는 70px*(3타임-1) 변동값
                                                예시 : 7시 00분 시작기준으로 3타임(1타임당 30분)일때 : 70px*(3타임-1)
                                            -->
                                            <!-- <button @click="pop.showSchedule" class="click has" style="top:35px; height: calc( 70px * 2 );">Heather K<br>(7:00~08:30)</button> -->
                                            <!-- //예약이 있는경우 -->
                                        </div>
                                    </div>
                                </template>
                                <!-- //30분간격 행 loop -->
                            </div>

                            <div class="reserve-body">
                                <div v-for="(irow, i) in bbs.list" :key="i" class="reserve-cell" :style="{ 'width':1154/bbs.list.length+'px'}">
                                    <!-- 예약이 있는경우 
                                        예약한 분이 30분 이하일때 top은 7px 고정값, height 55px 고정값
                                        예약한 분이 30분 이상일때 top은 35px 고정값, height는 70px*(3타임-1) 변동값
                                        예시 : 7시 00분 시작기준으로 3타임(1타임당 30분)일때 : 70px*(3타임-1)
                                    -->
                                    <div v-for="(jrow, j) in irow.list" :key="j" :style="{ 'top':jrow.top+'px', 'height':jrow.height+'px' }" class="reserve-bg">
                                        <button v-if="jrow.is_vacation=='Y'" @click="pop.showSchedule(jrow.idx)" class="btn-reserve vacation">{{ (jrow.vtype=='am'?'[오전반차] ':(jrow.vtype=='pm'?'[오후반차] ':'[연차] ')) }}{{ jrow.title }}</button>
                                        <button v-else @click="pop.showSchedule(jrow.idx)" class="btn-reserve">{{ jrow.title }}<br>({{ jrow.stime + '~' + jrow.etime }})</button>
                                    </div>
                                    <!-- //예약이 있는경우 -->
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import MITutorListPopup from '@/components/popup/ResourceManagement/MITutorListPopup.vue'
import MIViewPopup from '@/components/popup/ResourceManagement/MIViewPopup.vue'
import {useStore} from "vuex";


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotDatePicker,
        MITutorListPopup,
        MIViewPopup,
    },
    setup() {
        const router = useRouter();
        const toast = useToast()
        const store = useStore();
        const pop = reactive({
            idx   : 0,
            tdate : "",
            // Popup 노출 여부
            isShowTutorList : false,
            isShowSchedule  : false,

            showTutorAdd : () => {
                pop.isShowTutorList = true;
            },
            hideTutorAdd : () => {
                pop.isShowTutorList = false;
                bbs.doSearch();
            },

            showSchedule : (idx) => {
                sessionStorage.setItem('MIScheduleDate', JSON.stringify({
                    today : bbs.today
                }));
                pop.idx   = idx;
                pop.tdate = bbs.today;
                pop.isShowSchedule = true;
            },
            hideSchedule : () => {
                pop.isShowSchedule = false;
                pop.idx = 0;
                bbs.doSearch();
            },
        });


        const bbs = reactive({
            date_labels : [],
            time_labels : [],
            time_count  : 0,

            today : "",

            list : [], total : 0,

            doPrev : () => {
                let td = new Date(bbs.today);
                let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate()-1);
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();
            },

            doNext : () => {
                let td = new Date(bbs.today);
                let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate()+1);
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();
            },

            showMonth : () => {
                sessionStorage.setItem('MIScheduleDate', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-MIScheduleMonth'
                });
            },

            addVacation : () => {
                sessionStorage.setItem('MIScheduleDate', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-MIVacationAdd'
                });
            },

            addSchedule : (idx_tutor, time) => {
                sessionStorage.setItem('MIScheduleDate', JSON.stringify({
                    today : bbs.today
                }));
                if( idx_tutor > 0 ){
                    sessionStorage.setItem('MIScheduleAdd', JSON.stringify({
                        today : bbs.today,
                        time  : time,
                        idx_tutor : idx_tutor,
                    }));
                } else {
                    sessionStorage.removeItem('MIScheduleAdd');
                }
                router.push({
                    name : 'ResourceManagement-MIScheduleAdd'
                });
            },

            makeTimeSchedules : () => {
                let nd = new Date(bbs.today);
                let td = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate(), 7, 0, 0);

                for(let i=0; i<bbs.list.length; i++){
                    let irow = bbs.list[i];

                    for(let j=0; j<irow.list.length; j++){
                        let jrow = irow.list[j];

                        jrow.top    = 0;
                        jrow.height = 0;

                        let s = 0;
                        for(let k=td.getTime(), l=0; k<td.getTime()+(3600*1000*15); k+=1800*1000, l++){
                            let kd = new Date(k);

                            let ktime = (kd.getHours()<10?'0'+kd.getHours():kd.getHours())
                                      + ':' + (kd.getMinutes()<10?'0'+kd.getMinutes():kd.getMinutes());

                            if( jrow.stime == ktime ){
                                s = l;
                            }
                            if( jrow.stime <= ktime && ktime < jrow.etime ){
                                jrow.height += 70;
                            }

                        }

                        jrow.top = s*70;

                        irow.list[j] = jrow;
                    }
                }
            },

            doSearch : () => {
                let params = {
                    tgdate : bbs.today
                };
                axios.get('/rest/resourceManagement/getMIScheduleDate', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.makeTimeSchedules();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            makeTimeLabels : () => {
                for(let i=7; i<23; i++){
                    if( i < 10 ){
                        bbs.time_labels.push( '0' + i + ':00');
                        bbs.time_labels.push( '0' + i + ':30');
                    } else if ( i == 22 ){
                        bbs.time_labels.push( i + ':00' );
                    } else {
                        bbs.time_labels.push( i + ':00');
                        bbs.time_labels.push( i + ':30');
                    }
                }

                bbs.time_count = bbs.time_labels.length - 1;
            },

        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            let today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());
            bbs.today = today;

            let ss = sessionStorage.getItem('MIScheduleDate');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today ) bbs.today = ss.today;
            }

            bbs.makeTimeLabels();

            bbs.doSearch();
        });

        return {pop, bbs,store};
    }
}
</script>

<style lang="scss" scoped>
.dayTable-box { 
    width:1200px; 
    .div-dayTable{
        position:relative;
        .reserve-body {
            position:absolute; top:70px; left:0; width:100%;
            .reserve-cell {
                position: relative; height:1px; float:left;

                .reserve-bg {
                    width:100%; background-color:rgba(0,0,0,0.01); position: absolute;
                }

                .btn-reserve {
                    margin:7px 20px;border: 0;font-size: 13px;font-weight: normal;line-height:160%;text-align: center;color: #fff;background-color: #ff854a;width: calc(100% - 40px); height:calc(100% - 16px);padding: 5px 23px;border-radius: 5px;z-index: 1;

                    &:hover {
                        background-color: #e7621f;
                    }
                    &.vacation {
                        background-color:#5d9cec;
                        &:hover {
                            background-color: #498adf;
                        }
                    }
                }
            }
        }
    }
}
.active {
    background-color:#efefef;
}
</style>