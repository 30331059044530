<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">전임교수(MI) 추가</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">

                <div class="dp-table w-100per">
                    <input type="text" v-model.trim="add.ename" class="w-300px h-30px float-left" maxlength="50" placeholder="이름을 입력해주세요.">
                    <button @click="add.doSubmit" class="btn-default h-30px float-left ml-10">추가</button>
                </div>

                <table class="tbl-light-list mt-50 mb-20">
                    <colgroup>
                        <col width="40">
                        <col width="60">
                        <col width="80">
                        <col width="*">
                        <col width="60">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label><input type="checkbox" @change="mod.checkAll" v-model="mod.is_all"></label>
                            </th>
                            <th>No</th>
                            <th>등록일</th>
                            <th>Tutor Name</th>
                            <th>수정</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td>
                                <label><input type="checkbox" v-model="mod.selected" :value="irow.idx"></label>
                            </td>
                            <td>{{ irow.num }}</td>
                            <td>{{ irow.wdate }}</td>
                            <td>
                                <input type="text" v-if="mod.idx==irow.idx" v-model="mod.ename" class="w-100per" maxlength="50">
                                <span v-else>{{ irow.ename }}</span>
                            </td>
                            <td>
                                <span v-if="mod.idx==irow.idx" @click="mod.doSubmit()" class="btn-view">저장</span>
                                <span v-else @click="mod.showModify(i)" class="btn-view">수정</span>
                            </td>
                        </tr>
                        <tr v-if="pop.total==0">
                            <td colspan="5">등록된 Tutor가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="pop.total" :list_per_page="pop.rows" v-model="pop.page" @change="pop.doSearch"></CarrotPaging>

                <div class="dp-table w-100per mt-20">
                    <button @click="mod.doDelete" class="btn-default float-right">삭제</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';


export default {
    name: 'MITutorListPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast();
        const store = useStore();

        const add = reactive({
            ename : "",

            doSubmit : () => {
                let params = {
                    ename : add.ename
                };
                if( !params.ename ){
                    Swal.fire({
                        title : "전임교수(MI) 추가",
                        text  : "이름을 입력해주세요."
                    });
                    return;
                }
                axios.post("/rest/resourceManagement/addTutor", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("등록하였습니다.");
                        add.ename  = "";
                        mod.select = [];
                        mod.ename  = "";
                        mod.idx    = 0;
                        pop.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const mod = reactive({
            idx   : 0,
            ename : "",

            is_all   : false,
            selected : [],

            checkAll : () => {
                if( mod.is_all == true ){
                    for(let i=0; i<pop.list.length; i++){
                        let irow = pop.list[i];
                        mod.selected.push(irow.idx);
                    }
                } else {
                    mod.is_all   = false;
                    mod.selected = [];
                }
            },

            doDelete : () => {
                let params = {
                    selected : mod.selected
                };

                if( params.selected.length <= 0 ){
                    toast.error("삭제할 대상을 선택하세요.");
                    return;
                }

                Swal.fire({
                    title : '전임교수(MI) 삭제',
                    text  : params.selected.length + '명을 삭제하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        axios.post("/rest/resourceManagement/checkDelTutor", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("삭제하였습니다.");
                                pop.doSearch();
                            } else if( res.data.err == 201 ){
                                Swal.fire({
                                    title : '전임교수(MI) 삭제',
                                    text  : '해당 강사에게 잡힌 스케줄이 있습니다. 그래도 삭제하시겠습니까?',
                                    showCancelButton : true
                                }).then((res) => {
                                    if( res.isConfirmed == true ){
                                        axios.post("/rest/resourceManagement/delTutor", params).then((res) => {
                                            if( res.data.err == 0 ){
                                                toast.success("삭제하였습니다.");
                                                pop.doSearch();
                                            } else {
                                                if(res.data.err_msg) toast.error(res.data.err_msg);
                                            }
                                        });
                                    }
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            showModify : (i) => {
                mod.idx   = pop.list[i].idx;
                mod.ename = pop.list[i].ename;
            },

            doSubmit : () => {
                let params = {
                    idx   : mod.idx,
                    ename : mod.ename
                };
                if( !params.ename ){
                    Swal.fire({
                        title : "전임교수(MI) 수정",
                        text  : "이름을 입력해주세요."
                    });
                    return;
                }
                axios.post("/rest/resourceManagement/modTutor", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("수정하였습니다.");
                        mod.idx   = 0;
                        mod.ename = "";
                        pop.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const pop = reactive({
            page : 1,
            rows : 30,
            list : [], total : 0,

            doSearch : () => {
                let params = {
                    page : pop.page,
                    rows : pop.rows
                };
                axios.get('/rest/resourceManagement/getTutorList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            if( !store.state.gthr_auth ) {
                pop.onClose();
                Swal.fire({
                    title : '전임교수(MI) 추가',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            pop.doSearch();
        });

        return {pop,add,mod};
    }
}
</script>
<style lang="scss" scoped>
</style>