<template>
    <div class="carrot-layer-bg">
        <MIViewDelPopup v-model:deleted.sync="pop.is_deleted" :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowDelete" @close="pop.hideDSchedule"></MIViewDelPopup>

        <div class="carrot-layer w-400px">
            <div>
                <p class="title">{{ pop.info.tutor_name }} {{ pop.info.is_vacation=='Y'?'휴가':'' }}</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="120">
                        <col width="280">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>기간</th>
                            <td>{{ pop.info.sdate }} ~ {{ pop.info.edate }}</td>
                        </tr>
                        <tr>
                            <th>시간</th>
                            <td>{{ pop.info.stime }}~{{ pop.info.etime }}</td>
                        </tr>
                        <tr>
                            <th>{{ pop.info.is_vacation=='Y'?'비고':'스케줄' }}</th>
                            <td>{{ pop.info.title }}</td>
                        </tr>
                        <tr>
                            <th>등록자</th>
                            <td>{{ pop.info.ename + '(' + pop.info.kname + ')' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button @click="pop.onClose" class="btn-default float-left">닫기</button>
                <button @click="pop.showDSchedule" class="btn-default float-left ml-10">삭제</button>
                <button @click="pop.showModify" class="btn-default float-right">수정</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import MIViewDelPopup from '@/components/popup/ResourceManagement/MIViewDelPopup.vue'

export default {
    name: 'MIViewPopup',
    components:{
        MIViewDelPopup
    },
    props: {
        idx: {
            type: [ Number, String ],
            default: 0
        },
        tdate: {
            type: String,
            default: ""
        }
    },
    setup(props, {emit}) {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            isShowDelete : false,
            is_deleted   : false,

            idx   : 0,
            tdate : "",
            info  : {},

            showDSchedule : () => {
                pop.isShowDelete = true;
            },

            hideDSchedule : () => {
                pop.isShowDelete = false;
                if( pop.is_deleted == true ){
                    pop.onClose();
                }
            },

            showVacation : () => {
                router.push({
                    name : 'ResourceManagement-MIVacationMod-idx',
                    params : {
                        idx : pop.idx
                    }
                })
            },

            showModify : () => {
                if( pop.info.is_vacation == "Y" ){
                    pop.showVacation();
                    return;
                }
                router.push({
                    name : 'ResourceManagement-MIScheduleMod-idx',
                    params : {
                        idx : pop.idx
                    }
                })
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx
                };
                axios.get('/rest/resourceManagement/getMIScheduleInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx   = props.idx;
            pop.tdate = props.tdate;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>